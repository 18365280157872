import React, { useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import Layout from "../components/layout"
import SEO from "../components/seo"

const videos = ["Lj-K-BXBTPY", "fC5It-6Yv6c", "Kx2LME6WRwQ"]

const NavIcon = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;

        svg {
          font-size: 50px;
        }
      `}
    >
      {children}
    </div>
  )
}

NavIcon.propTypes = {
  children: PropTypes.element.isRequired,
}

const Music = () => {
  const [index, setIndex] = useState(0)

  function next() {
    setIndex(curr => (curr < videos.length - 1 ? curr + 1 : curr))
  }

  function prev() {
    setIndex(curr => (curr > 0 ? curr - 1 : curr))
  }

  return (
    <Layout>
      <SEO title="Music" />
      <div
        css={css`
          margin: 70px auto;
          width: 90%;
          position: relative;
          display: flex;

          @media (max-width: 700px) {
            width: 100%;
          }
        `}
        dir="ltr"
      >
        <NavIcon>
          <MdKeyboardArrowLeft onClick={prev} />
        </NavIcon>
        <div
          css={css`
            flex: 1;
          `}
        >
          <iframe
            title="video"
            id="player"
            type="text/html"
            width="100%"
            height="390"
            src={`https://www.youtube.com/embed/${videos[index]}?enablejsapi=1`}
            frameBorder="0"
          />
        </div>
        <NavIcon>
          <MdKeyboardArrowRight onClick={next} />
        </NavIcon>
      </div>
    </Layout>
  )
}

export default Music

/*
 * https://www.youtube.com/watch?v=Lj-K-BXBTPY&feature=emb_title
 * https://www.youtube.com/watch?v=fC5It-6Yv6c&feature=emb_title
 * https://www.youtube.com/watch?v=Kx2LME6WRwQ&feature=emb_title
 * https://www.youtube.com/watch?v=UoSXL13uVYU&feature=emb_title
 * https://www.youtube.com/watch?v=vXRmL0MS1Nw&feature=emb_title
 * https://www.youtube.com/watch?v=BdrKeMCkz2k&feature=emb_title
 * https://www.youtube.com/watch?v=rr0ut8E-BtA
 * https://www.youtube.com/watch?time_continue=3571&v=9vWxviXkQI8&feature=emb_title
 * https://www.youtube.com/watch?time_continue=1&v=n6VjsvT6o3s&feature=emb_title
 */
/*
      <iframe
        title="youtube"
        id="player"
        type="text/html"
        width="640"
        height="390"
        src="http://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://localhost:8000"
        frameBorder="0"
      />
  */
